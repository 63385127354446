import { useState } from "react";
import { LinkLocation } from "./LinkLocation";
import { RouteNavigation } from "../Routes";
import Logo from "@images/logo.png";
import { ImCross } from "react-icons/im";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaFacebookF, FaTwitter, FaYoutube, FaInstagram } from "react-icons/fa";

export const HeaderMenu = () => {
  return (
    <ul className="items-center hidden space-x-8 lg:flex text-black">
      <li>
        <LinkLocation
          to={RouteNavigation.home}
          target="_top"
          className="font-medium tracking-wide"
        >
          Home
        </LinkLocation>
      </li>
      <li>
        <LinkLocation
          to={RouteNavigation.aboutus}
          target="_top"
          className="font-medium tracking-wide"
        >
          About Us
        </LinkLocation>
      </li>
      <li>
        <LinkLocation
          to={RouteNavigation.services}
          target="_top"
          className="font-medium tracking-wide"
        >
          Services
        </LinkLocation>
      </li>
      <li>
        <LinkLocation
          to={"/under-construction"}
          target="_top"
          className="font-medium tracking-wide"
        >
          Testimonial
        </LinkLocation>
      </li>
      <li>
        <LinkLocation
          to={"/under-construction"}
          target="_top"
          className="font-medium tracking-wide"
        >
          Product
        </LinkLocation>
      </li>
      <li>
        <LinkLocation
          to={RouteNavigation.contactus}
          target="_top"
          className="font-medium tracking-wide"
        >
          Contact us
        </LinkLocation>
      </li>
    </ul>
  );
};

export const MobileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <div className="lg:hidden">
      <button
        aria-label="Open Menu"
        title="Open Menu"
        className="p-2 -mr-1 transition duration-200 rounded focus:outline-none"
        onClick={() => setIsMenuOpen(true)}
      >
        <RxHamburgerMenu className="text-black" />
      </button>
      {isMenuOpen && (
        <div className="absolute top-0 left-0 w-full z-10">
          <div className="p-5 bg-white border rounded shadow-sm">
            <div className="flex items-center justify-between mb-4">
              <div>
                <LinkLocation
                  to={RouteNavigation.home}
                  className="flex items-center"
                  target="_top"
                >
                  <img
                    src={Logo}
                    className="mr-3 h-6 sm:h-9"
                    alt="logix_logo"
                  />
                </LinkLocation>
              </div>
              <div>
                <button
                  title="Close Menu"
                  className="p-2 -mt-2 -mr-2 transition duration-200 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <ImCross className="text-black" />
                </button>
              </div>
            </div>
            <nav>
              <ul className="space-y-4 text-black">
                <li>
                  <LinkLocation
                    to={RouteNavigation.home}
                    target="_top"
                    className="font-medium tracking-wide"
                  >
                    Home
                  </LinkLocation>
                </li>
                <li>
                  <LinkLocation
                    to={RouteNavigation.aboutus}
                    target="_top"
                    className="font-medium tracking-wide"
                  >
                    About us
                  </LinkLocation>
                </li>
                <li>
                  <LinkLocation
                    to={RouteNavigation.services}
                    target="_top"
                    className="font-medium tracking-wide"
                  >
                    Services
                  </LinkLocation>
                </li>
                <li>
                  <LinkLocation
                    to={"/under-construction"}
                    target="_top"
                    className="font-medium tracking-wide"
                  >
                    Testimonial
                  </LinkLocation>
                </li>
                <li>
                  <LinkLocation
                    to={"/under-construction"}
                    target="_top"
                    className="font-medium tracking-wide"
                  >
                    Product
                  </LinkLocation>
                </li>
                <li>
                  <LinkLocation
                    to={RouteNavigation.contactus}
                    target="_top"
                    className="font-medium tracking-wide"
                  >
                    Contact us
                  </LinkLocation>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
};

export const FooterMenu = () => {
  return (
    <div className="w-full lg:w-6/12 px-4 m-auto">
      <div className="flex items-top mb-6">
        <div className="w-full lg:w-4/12 ml-auto">
          <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
            Quick Links
          </span>
          <ul className="mt-2 space-y-2">
            <li>
              <LinkLocation
                to={RouteNavigation.home}
                target="_top"
                className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                Home
              </LinkLocation>
            </li>
            <li>
              <LinkLocation
                to={RouteNavigation.services}
                target="_top"
                className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                Services
              </LinkLocation>
            </li>
            <li>
              <LinkLocation
                to={"/under-construction"}
                target="_top"
                className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                Testimonial
              </LinkLocation>
            </li>
            <li>
              <LinkLocation
                to={"/under-construction"}
                target="_top"
                className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                Product
              </LinkLocation>
            </li>
          </ul>
        </div>
        <div className="w-full lg:w-4/12 px-4">
          <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
            Support
          </span>
          <ul className="mt-2 space-y-2">
            <li>
              <LinkLocation
                to={RouteNavigation.aboutus}
                target="_top"
                className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                About us
              </LinkLocation>
            </li>
            <li>
              <LinkLocation
                to={RouteNavigation.contactus}
                target="_top"
                className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                Contact us
              </LinkLocation>
            </li>
            <li>
              <LinkLocation
                to={RouteNavigation.privacyPolicy}
                target="_top"
                className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                Privacy Policy
              </LinkLocation>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export const FooterSocialMediaMenu = () => {
  return (
    <div className="flex mt-6 lg:mb-0 mb-6">
      <LinkLocation
        to={"https://www.facebook.com/Logixassociates/"}
        target={"_blank"}
      >
        <div className="bg-white hover:bg-blue-600 text-blue-600 hover:text-white border border-blue-600 shadow-2xl text-lightBlue-400 font-normal h-8 w-8 items-center justify-center align-center rounded-full mr-2 p-7px">
          <FaFacebookF />
        </div>
      </LinkLocation>
      <LinkLocation to={"https://www.instagram.com/logixassociates/"} target={"_blank"}>
        <div className="bg-white hover:bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 
        text-red-200 hover:text-white border border-red-200 hover:border-white shadow-2xl font-normal h-8 w-8 items-center 
        justify-center align-center rounded-full mr-2 p-7px">
          <FaInstagram />
        </div>
      </LinkLocation>
      <LinkLocation to={"https://twitter.com/LogixAssociates/"} target={"_blank"}>
        <div className="bg-white hover:bg-blue-400 text-blue-400 hover:text-white border border-blue-400 shadow-2xl font-normal h-8 w-8 items-center justify-center align-center rounded-full mr-2 p-7px">
          <FaTwitter />
        </div>
      </LinkLocation>
      <LinkLocation
        to={"https://www.youtube.com/channel/UCquz_6VhYW2qgxVyNNedFNw"}
        target={"_blank"}
      >
        <div className="bg-white hover:bg-red-600 text-red-600 hover:text-white border border-red-600 shadow-2xl text-lightBlue-400 font-normal h-8 w-8 items-center justify-center align-center rounded-full mr-2 p-7px">
          <FaYoutube />
        </div>
      </LinkLocation>
    </div>
  );
};