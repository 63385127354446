import CarouselBanner from "./HomeCarousel";
import HomepageContent from "./HomepageContent";

const Home = () => {
  return (
    <div>
      <CarouselBanner />
      <HomepageContent />
    </div>
  );
};

export default Home;
