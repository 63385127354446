import Services from "../Json/Service.json";
const ModelsList = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="lg:w-1/2 w-full mb-6 lg:mb-0 px-5 py-2">
        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
        Services
        </h1>
        <div className="h-1 w-16 bg-blue-900 rounded"></div>
      </div>
      <div className="container px-5 py-8 mx-auto">
        <div className="flex flex-wrap -m-4">
          {/* Map all the Services from the json */}
          {Services.map((service) => {
            return (
              <div
                className="lg:w-1/4 md:w-1/2 p-4 w-full hover:cursor-pointer"
                key={service.id}
              >
                  <div className="block relative h-48 rounded overflow-hidden">
                    <img
                      alt="model_image"
                      className="w-[300px] md:w-auto h-[200px] md:h-full m-auto block transition ease-in-out delay-150 hover:-translate-y-1 scale-90 hover:scale-100 duration-300"
                      src={service.img}
                    />
                  </div>
                  <div className="mt-4">
                    <h3 className="text-blue-900 font-semibold text-base tracking-widest mb-1 text-center">
                      {service.title}
                    </h3>
                  </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ModelsList;
