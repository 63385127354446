import { LinkLocation } from "../Components/LinkLocation";
import Lottie from "react-lottie";
import animationData from "../Json/PageNotFoundAnimation.json";

const PageNotFound = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <section class="flex items-center h-full py-8 px-16">
      <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div class="max-w-md text-center">
          <Lottie options={defaultOptions} height={200} width={200} />
          <h2 class="my-2 font-extrabold text-6xl">
            <span class="sr-only">Error</span>404
          </h2>
          <p class="text-2xl font-semibold md:text-3xl">
            Sorry, we couldn't find this page.
          </p>
          <p class="mt-4 mb-8">
            But dont worry, you can find plenty of other things on our homepage.
          </p>
          <LinkLocation
            rel="noopener noreferrer"
            href="#"
            class="px-8 py-3 font-semibold rounded"
          >
            Back to homepage
          </LinkLocation>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
