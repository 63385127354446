import React from "react";
import Marquee from "react-fast-marquee";
import UST from "@images/brands/UST.jpg";
import Anchako from "@images/brands/anchako.png";
import CTCRI from "@images/brands/ctcri.png";
import CyberChasse from "@images/brands/cyber_chasse.png";
import Decathlon from "@images/brands/decathlon.png";
import FakeehTech from "@images/brands/fakeeh_tech.png";
import ICAR from "@images/brands/icar.png";
import Malabar from "@images/brands/malabar.png";
import Melco from "@images/brands/melco.png";
import Nicoa from "@images/brands/nicoa.png";
import Philips from "@images/brands/philips.png";
import Quilon from "@images/brands/quilon.png";
import Sila from "@images/brands/sila.png";
import Sooryaprabha from "@images/brands/sooryaprabha.png";
import Stryder from "@images/brands/stryder.png";
import Sunview from "@images/brands/sunview.png";
import Tata from "@images/brands/tata.jpg";
import Tatwamayi from "@images/brands/tatwamayi.jpeg";
import Techvantage from "@images/brands/techvantage.png";
import Muthoot from "@images/brands/muthoot.png";
import MasterServ from "@images/brands/master_serv.png";
import McDonalds from "@images/brands/mcDonald.jpeg";
import SobhaProjects from "@images/brands/sobha_projects.jpeg";
import VCTech from "@images/brands/vc_tech.png";
import TechSis from "@images/brands/tech_sis.jpeg";

const ClientsMarquee = () => (
  <section>
    <div className="mb-6 text-center">
      <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
        Our Top Clients
      </h1>
      <div className="h-1 w-20 bg-blue-900 rounded m-auto"></div>
    </div>
    <Marquee
      autoFill={true}
      play={true}
      style={{ width: "90%", margin: "auto" }}
    >
      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={UST} alt="ust" className='h-16 w-16'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={Anchako} alt="Anchako" className='h-16 w-16'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={CTCRI} alt="CTCRI" className='h-16 w-16'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={CyberChasse} alt="CyberChasse" className='h-16 w-24'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={Decathlon} alt="Decathlon" className='h-16 w-18'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={FakeehTech} alt="FakeehTech" className='h-16 w-18'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={ICAR} alt="ICAR" className='h-16 w-16'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={Malabar} alt="Malabar" className='h-16 w-18'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={Melco} alt="Melco" className='h-16 w-18'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={Nicoa} alt="Nicoa" className='h-16 w-16'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={Philips} alt="Philips" className='h-16 w-16'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={Quilon} alt="Quilon" className='h-18 w-20'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={Sooryaprabha} alt="Sooryaprabha" className='h-16 w-18'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={Sila} alt="Sila" className='h-16 w-16'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={Stryder} alt="Stryder" className='h-16 w-16'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={Sunview} alt="Sunview" className='h-16 w-16'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={Tata} alt="Tata" className='h-16 w-16'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={Tatwamayi} alt="Tatwamayi" className='h-16 w-16' />
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={Techvantage} alt="Techvantage" className='h-16 w-18'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={Muthoot} alt="Muthoot" className='h-16 w-18'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={MasterServ} alt="MasterServ" className='h-16 w-18'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={McDonalds} alt="McDonalds" className='h-16 w-18'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={SobhaProjects} alt="SobhaProjects" className='h-16 w-18'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={VCTech} alt="VCTech" className='h-16 w-18'/>
        </div>
      </div>

      <div className="px-2">
        <div className="w-fit m-auto">
          <img src={TechSis} alt="TechSis" className='h-16 w-18'/>
        </div>
      </div>
    </Marquee>
  </section>
);

export default ClientsMarquee;
