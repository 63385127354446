import ClientsMarquee from "./ClientsMarquee";
import MEP from "@images/mep.webp";
import Tally from "@images/pos-to-tally.png";
import ScrollAnimation from "react-animate-on-scroll";
import CCTV from "@images/cctv.jpg";
import Property from "@images/property.webp";

const HomepageContent = () => {
  return (
    <div>
      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-4 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <div className="w-fit m-auto">
              <div>
                <ScrollAnimation animateIn="bounceInLeft" animateOnce={true}>
                  <img
                    className="object-cover object-center rounded"
                    alt="a_girl_paying_bill_through_phone"
                    src={CCTV}
                  />
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              Next-Gen Security System
            </h1>
            <p className="mb-8 leading-8 text-justify">
            "Logix" emerges as a pioneering force in the world of technology, driven by innovation and a relentless pursuit of excellence. With a focus on next-gen solutions, Logix continually pushes the boundaries of what's possible. Its commitment to efficiency, reliability, and user-friendliness is evident in every product and service it offers. Whether it's designing cutting-edge software, crafting advanced hardware, or delivering top-notch IT consulting, Logix consistently delivers solutions that transform industries and elevate businesses. Logix, where technology meets precision, is at the forefront of shaping the future.
            </p>
          </div>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-4 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0 block md:hidden">
            <div className="w-fit m-auto">
              <div>
                <ScrollAnimation animateIn="bounceInRight" animateOnce={true}>
                  <img
                    className="object-cover object-center rounded-md"
                    alt="house_cctv"
                    src={MEP}
                  />
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              MEP Services
            </h1>
            <p className="mb-8 leading-8 text-justify">
            Logix MEP services epitomize the pinnacle of excellence in Mechanical, Electrical, and Plumbing solutions. With a rich history of delivering precision-engineered systems, Logix stands as a beacon of innovation and reliability in the industry. Our team of expert engineers and technicians meticulously design, install, and maintain MEP systems that are not just efficient but sustainable as well. From cutting-edge HVAC systems to advanced electrical infrastructure and eco-friendly plumbing solutions, Logix is committed to creating environments that are not only comfortable but also environmentally responsible. When you choose Logix for your MEP needs, you're choosing quality, expertise, and a future-focused approach to building services that set new standards in the industry.
            </p>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0 lg:pl-24 md:pl-16 hidden md:block">
            <div className="w-fit m-auto">
              <div>
                <ScrollAnimation animateIn="bounceInRight" animateOnce={true}>
                  <img
                    className="object-cover object-center rounded-md"
                    alt="house_cctv"
                    src={MEP}
                  />
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-4 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <div className="w-fit m-auto">
              <div>
                <ScrollAnimation animateIn="bounceInLeft" animateOnce={true}>
                  <img
                    className="object-cover object-center rounded"
                    alt="image_of_person_with_a_mobile"
                    src={Property}
                  />
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
            Property Management Services
            </h1>
            <p className="mb-8 leading-8 text-justify">
            Logix Property Management Services redefines the art of property management through a commitment to excellence and a customer-centric approach. With a deep understanding of the real estate landscape, Logix provides tailored solutions that optimize property performance and enhance the value of investments. From comprehensive maintenance and repairs to efficient tenant management and financial oversight, Logix ensures that property owners can enjoy peace of mind and a steady return on their investments. Our team's expertise in property laws and regulations, coupled with cutting-edge technology, results in a seamless, transparent, and stress-free property management experience. Logix Property Management Services sets the gold standard for managing and maximizing the potential of real estate assets.
            </p>
          </div>
        </div>
      </section>

      <ClientsMarquee />

      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-4 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0 block md:hidden">
            <div className="w-fit m-auto">
              <div>
                <ScrollAnimation animateIn="bounceInRight" animateOnce={true}>
                  <img
                    className="object-cover object-center rounded"
                    alt="a_women_using_computer"
                    src={Tally}
                  />
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
            Integration and Services
            </h1>
            <p className="mb-8 leading-8 text-justify">
            Logix Integration and Services is your trusted partner in navigating the complex landscape of technology integration and solutions. With a track record of excellence, Logix brings a wealth of experience and expertise to the table. Whether it's streamlining your IT infrastructure, implementing state-of-the-art software systems, or optimizing your data management, Logix ensures that your organization operates at peak efficiency. Our commitment to seamless integration and unparalleled service means that you can focus on your core business while we handle the intricacies of technology. Logix Integration and Services, where innovation and reliability converge, is here to empower your success.
            </p>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0 hidden md:block">
            <div className="w-fit m-auto">
              <div>
                <ScrollAnimation animateIn="bounceInRight" animateOnce={true}>
                  <img
                    className="object-cover object-center rounded"
                    alt="a_women_using_computer"
                    src={Tally}
                  />
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default HomepageContent;
