import { LinkLocation } from "./LinkLocation";
import { RouteNavigation } from "../Routes";
import Logo from "@images/logo.png";
import { HeaderMenu, MobileMenu } from "./Menus";

const Header = () => {
  return (
    <header>
      <div className="bg-white px-4 py-5 mx-auto w-full md:px-24 lg:px-8">
        <div className="relative flex items-center justify-between">
          {/* Desktop View Menu */}
          <LinkLocation
            to={RouteNavigation.home}
            className="flex items-center"
            target="_top"
          >
            <img
              src={Logo}
              className="mr-3 h-6 sm:h-12"
              alt="logo"
            />
          </LinkLocation>

          <HeaderMenu />

          {/* Mobile View Menu */}
          <MobileMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
