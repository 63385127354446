import ScrollAnimation from "react-animate-on-scroll";
import HomeSecurity from "@images/home_security.png";
import TeamWork from "@images/teamwork.jpg";
import Aboutus from "@images/cable.jpg";

const AboutUs = () => {
  return (
    <div>
      <div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0">
        <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
          <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="currentColor"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
          <img
            className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
            src={Aboutus}
            alt=""
          />
        </div>
        <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
          <div className="mb-4 lg:my-40 lg:max-w-lg lg:pr-5">
            <p className="inline-block px-3 py-px mb-4 text-md font-semibold tracking-wider text-gray-700">
              Logix Associates
            </p>
            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Drive your business <br className="hidden md:block" />
              to next{" "}
              <span className="inline-block text-orange-500">level</span>
            </h2>
            <p className="pr-5 mb-5 text-base text-gray-700 md:text-lg text-justify">
              LOGIX offers comprehensive Electronic Security, IT Solutions, Property and Utility Management services in Thiruvanathapuram, Alappuzha, and Palakkad. Renowned for top-tier Technical Services, we prioritize customer satisfaction and deliver tailored solutions across Kerala, serving projects of all scales.
            </p>
          </div>
        </div>
      </div>

      <section className="text-gray-600 body-font">
        <div className="flex items-center space-x-4 py-5">
          <div className="flex-1 border-t border-gray-400"></div>
          <span className="self center font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">What we are</span>
          <div className="flex-1 border-t border-gray-400"></div>
        </div>
        <div className="container mx-auto flex px-5 py-4 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <div className="w-fit m-auto">
              <div>
                <ScrollAnimation animateIn="bounceInLeft" animateOnce={true}>
                  <img
                    className="object-cover object-center rounded"
                    alt="home_security"
                    src={HomeSecurity}
                  />
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <p className="mb-8 leading-9 text-justify">
              LOGIX Associates stands as the definitive comprehensive solution encompassing a diverse spectrum of offerings, including Electronic Security Systems, IT Solutions, Property Management, Utility Management, and a wide array of Online Services. Originating in Thiruvanathapuram and extending its reach through branches in Alappuzha and Palakkad, our firm has meticulously cultivated a robust reputation for the delivery of top-tier Technical Services, catering to the multifaceted requirements of Security Systems and IT Solutions. Furthermore, we provide a diverse portfolio of Services intricately linked to Property and Utility Management. Our bedrock principle pivots on fostering customer trust and satisfaction, forming the cornerstone of our business ethos. Our overarching mission is to deliver bespoke solutions and superior products that resonate with the unique needs of our esteemed clients.
            </p>
          </div>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-4 md:flex-row flex-col items-center">
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0 lg:pl-24 md:pl-16 block md:hidden">
            <div className="w-fit m-auto">
              <div>
                <ScrollAnimation animateIn="bounceInRight" animateOnce={true}>
                  <img
                    className="object-cover object-center rounded"
                    alt="Team_work"
                    src={TeamWork}
                  />
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left items-center text-center">
            <p className="mb-8 leading-9 text-justify">
              Manifesting as a dynamic and rapidly expanding entity, LOGIX Associates proudly presents an expansive assortment of products and services, meticulously customized to the ever-evolving landscape of our diverse clientele. Presently, our service footprint spans the entirety of Kerala, underscoring our unwavering commitment to achieving excellence through continuous growth. Commencing from our modest origins, we have steadfastly augmented both our team and expertise, thereby equipping us to seamlessly extend our services across projects of varying scales, ranging from small-scale endeavors to intricate, large-scale undertakings.
            </p>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0 lg:pl-24 md:pl-16 hidden md:block">
            <div className="w-fit m-auto">
              <div>
                <ScrollAnimation animateIn="bounceInRight" animateOnce={true}>
                  <img
                    className="object-cover object-center rounded"
                    alt="Team_work"
                    src={TeamWork}
                  />
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
