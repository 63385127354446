import { SlCallIn } from "react-icons/sl";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { useState } from "react";
import Lottie from "react-lottie";
import MapLoading from "../Json/MapLoadingAnimation.json";

const ContactUs = () => {
  const [isLoading, setIsLoading] =useState(true);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: MapLoading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <section>
      <div className="flex flex-wrap w-full py-2 px-5">
        <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
            Contact Us
          </h1>
          <div className="h-1 w-20 bg-blue-900 rounded"></div>
        </div>
      </div>
      <div className="my-4 mx-auto md:px-6">
        <div className="px-4">
          <div className="h-[300px] w-full">
          {isLoading ? (
          <Lottie options={defaultOptions} height={200} width={200} />
        ) : null}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.527622280708!2d76.8962026756802!3d8.545154296373655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05bfd53a8967b7%3A0x26bb3970471e3886!2sLogix%20Associates!5e0!3m2!1sen!2sin!4v1696180743536!5m2!1sen!2sin"
              className="w-full h-full"
              title="office_location"
              onLoad={()=>setIsLoading(false)}
            ></iframe>
          </div>
        </div>
      </div>
      <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <h1 className="text-xl font-medium title-font mb-2 text-gray-900">
          Let's keep in touch!
        </h1>
        <div className="grid max-w-screen-lg gap-8 row-gap-10 mx-auto lg:grid-cols-2 py-5">
          <div className="w-full flex flex-col max-w-md sm:mx-auto sm:flex-row">
            <div className="mr-4">
              <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-primary-100 text-primary">
                <HiOutlineOfficeBuilding size={"20px"} />
              </div>
            </div>
            <div>
              <h6 className="mb-3 text-xl font-bold leading-5">
                Office
              </h6>
              <p>Logix Associates</p>
              <p>TC 97/177-1, Rajeev Nagar</p>
              <p>Sreekaryam -Kulathoor Road</p>
              <p>Kulathoor P O</p>
              <p>Trivandrum 695583</p>
            </div>
          </div>
          <div className="w-full flex flex-col max-w-md sm:mx-auto sm:flex-row">
            <div className="mr-4">
              <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-primary-100 text-primary">
                <SlCallIn size={"20px"} />
              </div>
            </div>
            <div>
              <h6 className="mb-3 text-xl font-bold leading-5">Sales Enquiry</h6>
              <p>Mail : sales@logixassociates.com</p>
              <p>Phone : <b>+91-9895779266</b></p>
            </div>
          </div>
          <div className="w-full flex flex-col max-w-md sm:mx-auto sm:flex-row">
            <div className="mr-4">
              <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-primary-100 text-primary">
                <SlCallIn size={"20px"} />
              </div>
            </div>
            <div>
              <h6 className="mb-3 text-xl font-bold leading-5">
                Service Enquiry
              </h6>
              <p>Mail : services@logixassociates.com</p>
              <p>Office : <b>+91-8301041267</b></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ContactUs;
