export const PrivacyPolicy = () => {
  return (
    <div className="px-5">
      <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
        Privacy Policy
      </h1>
      <div className="h-1 w-20 bg-blue-900 rounded"></div>
      <div className="p-5 text-lg text-justify">
        <p>Welcome to www.logixassociates.com. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website.</p>
        <h2 className="font-semibold my-4">1. Information We Collect</h2>
        <div className="ml-5">
          <h3>2.1 Personal Information</h3>
          <p className="ml-5">We may collect personal information such as your name, email address, and other contact details when you voluntarily submit them through forms on our website.</p>
          <h3>2.2 Usage Data</h3>
          <p className="ml-5">We automatically collect information about your visit, including your IP address, browser type, referral source, and the pages you view on our site. This information is used to analyze trends, administer the site, and gather demographic information.</p>
        </div>
        <h2 className="font-semibold my-4">2. Use of Information</h2>
        <p className="ml-5">We use the collected information for the following purposes:</p>
        <p className="ml-10">To provide and maintain our website.<br />
          To improve our website and user experience.<br />
          To communicate with you, respond to inquiries, and send information related to our services.<br />
          To comply with legal obligations.
        </p>
        <h2 className="font-semibold my-4">3. Cookies and Tracking Technologies</h2>
        <p className="ml-5">We use cookies and similar tracking technologies to enhance your experience on our site. You can manage your cookie preferences through your browser settings.</p>
        <h2 className="font-semibold my-4">4. Third-Party Links</h2>
        <p className="ml-5">Our website may contain links to third-party websites. We are not responsible for the privacy practices of these sites, and we encourage you to review their privacy policies.</p>
        <h2 className="font-semibold my-4">5. Security</h2>
        <p className="ml-5">We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.</p>
        <h2 className="font-semibold my-4">6. Your Choices</h2>
        <p className="ml-5">You have the right to opt out of receiving promotional communications from us. You can do this by following the unsubscribe instructions provided in the emails we send or by contacting us directly.</p>
        <h2 className="font-semibold my-4">7. Changes to This Privacy Policy</h2>
        <p className="ml-5">We may update this Privacy Policy periodically to reflect changes in our practices. Please review this page for the latest information.</p>
        <h2 className="font-semibold my-4">8. Contact Us</h2>
        <p className="ml-5">If you have any questions or concerns about this Privacy Policy, please contact us at [hello@logixassociates.com].</p>
      </div>
    </div>
  )
}