import { Route, Routes } from "react-router-dom";
import Home from "./Homepage/Home";
import ServiceList from "./Services/ServiceList";
import ContactUs from "./Support/ContactUs";
import AboutUs from "./Support/AboutUs";
import PageNotFound from "./Support/PageNotFound";
import {PrivacyPolicy}  from "./Support/PrivacyPolicy";

export const RouteNavigation = {
  home: "/",
  services: "/services",
  contactus: "/contact-us",
  aboutus: "/about-us",
  privacyPolicy : "/privacy-policy",
  error: "*",
};

const GenericRoutes = () => {
  return (
    <Routes>
      <Route path={RouteNavigation.home} element={<Home />} />
      <Route path={RouteNavigation.services} element={<ServiceList />} />
      <Route path={RouteNavigation.contactus} element={<ContactUs />} />
      <Route path={RouteNavigation.aboutus} element={<AboutUs />} />
      <Route path={RouteNavigation.privacyPolicy} element={<PrivacyPolicy />} />
      <Route path={RouteNavigation.error} element={<PageNotFound />} />
    </Routes>
  );
};
export default GenericRoutes;
