import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Banner1 from "@images/banners/banner_1.mp4";
import Banner2 from "@images/banners/banner_2.webp";
import Banner3 from "@images/banners/banner_3.webp";
import Banner4 from "@images/banners/banner_4.webp";

const CarouselBanner = () => {
  const ShowBannerImage = (src) => {
    return (
    <>
      <img
        src={src.src}
        alt="banner_image"
        className="h-[250px] sm:h[350] md:h-[500px]"
      />
    </>
    )
  }
  return (
    <Carousel
      autoPlay={true}
      showThumbs={false}
      swipeable={true}
      infiniteLoop={true}
      showArrows={false}
      showStatus={false}
    >
      <div>
        <video className="h-[250px] sm:h[350] md:h-[500px] object-fill w-full" autoPlay loop muted>
          <source src={Banner1} type="video/mp4" />
        </video>
      </div>
      <ShowBannerImage src={Banner2} />
      <ShowBannerImage src={Banner3} />
      <ShowBannerImage src={Banner4} />
    </Carousel>
  );
};

export default CarouselBanner;
