import Logo from "@images/logo.png";
import { FooterMenu, FooterSocialMediaMenu } from "./Menus";

const Footer = () => {
  return (
    <footer className="relative bg-blueGray-200 pt-8 pb-6 border-t">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap text-left lg:text-left">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl fonat-semibold text-blueGray-700">
              <img src={Logo} alt="company_logo" style={{ width: "170px" }} />
            </h4>
            <div className="mt-4 lg:max-w-sm">
              <p className="mt-4 text-base text-gray-800">
                Find us on any of these platforms, <br /> we respond 1-2 business days.
              </p>
            </div>

            <FooterSocialMediaMenu />
          </div>

          <FooterMenu />
        </div>
        <hr className="my-4 border-blueGray-300" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-blueGray-500 font-semibold">
              © Copyright 2023 Logix Associates. All rights reserved. <br/>Designed by Akhil A G.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
